<template>
  <section class="section section-bzinfo text-center wow fadeInLeft">
    <div class="container">
      <h2 class="container-header-2 text-white">业务咨询</h2>
      <div class="container-sub-header justify-content-center align-items-center">
        <span>优质方案，解决所有技术难点</span>
      </div>
      <div class="button-more animate__animated animate__fadeInUp" @click="linkChat">立即咨询</div>
    </div>
  </section>
</template>
<script>
export default {
  name: "index",

  data() {
    return {
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      // indexro:"www.baidu.com"
    }
  },
  methods: {
    linkChat(){
      window.open(this.chatUrl,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    }
  }
}
</script>
